import { Control, DomUtil, Map } from "leaflet";

export const FILTERCONTROL = 'filterControl:click';

export class FilterControl extends Control {
  onAdd(map: Map){
    const container = DomUtil.create('div', 'filter-control leaflet-bar leaflet-control leaflet-control-load');
    container.title = "Open advanced filters"

    container.addEventListener('click', (event) => {
      event.stopPropagation();
      map.fire(FILTERCONTROL, { data: 'fired' });
    })

    return container;
  }
}
