import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { LoaderComponent } from "./loader.component";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(private snackBar: MatSnackBar) {}
  isOpen: boolean = false;
  show(show: boolean) {
    if (show && !this.isOpen) {
      this.snackBar.openFromComponent<LoaderComponent>(LoaderComponent, {
        horizontalPosition: 'center',
        verticalPosition : 'top'
      });
    } else {
      this.snackBar.dismiss();
    }
    this.isOpen = show;
  }
}
