<!-- <div class="nav-container">
  <div *ngIf="!loading" class="title">Map Viewer</div>
  <div *ngIf="loading" class="loader-container">
    <span>Loading data...</span>
    <div class="loader"></div>
  </div>
  <div class="search-container" *ngIf="isTextOpen">
    <input
      id="coordinates"
      class="search-text"
      [(ngModel)]="searchString"
      [placeholder]="placeholderString"
      (keyup.enter)="openSearch()"
    />
    <mat-icon class="icon" (click)="isTextOpen = !isTextOpen; searchString = ''"> close </mat-icon>
  </div>
  <mat-icon class="icon" (click)="openSearch()" title="Search Bar">
    search
  </mat-icon>
</div> -->
<div class="nav-container">
  <!-- <div class="title" (click)="openList()">Map Viewer</div> -->
  <div class="title">Map Viewer</div>
  <div>
    <button type="button" class="icon" (click)="menuStateChange()" title="Filter Menu">
      <img src="./assets/icons/filter.svg">
      <span>{{isFilterOpen? "Close": "Open"}} Filters</span>
    </button>
  </div>
  <!-- <input id="coordinates" class="search-text" [(ngModel)]="searchString" *ngIf="isTextOpen" placeholder="{{this.placeholderString}}"
   (keyup.enter)="openSearch()">
   <mat-icon class="icon"  *ngIf="isTextOpen" (click)="isTextOpen = !isTextOpen; searchString = ''"> close </mat-icon>
  <mat-icon class="icon" (click)="openSearch()" title="Search Bar">
    search
  </mat-icon> -->

</div>
