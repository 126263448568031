import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Map } from 'projects/leaflet/src/public-api';
import { FiltersDialogComponent } from './filters-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FilterDialogService {
  filterSetted: EventEmitter<string> = new EventEmitter<string>();
  constructor(private dialog: MatDialog) {}

  openDialog(map?: Map, category?:any) {

    this.dialog.open(FiltersDialogComponent, {
      disableClose: true,
      minWidth: '50vw',
      data: {
        map: map
      }
    }).afterClosed().subscribe(x => {
      if (x) {
        this.filterSetted.emit(x)
      } else {
        map?.addFilter(category)
      }

    });
  }
}
