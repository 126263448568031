<ng-container *ngFor="let item of categories">
    <div class="category-container" *ngIf="item.categories && item.categories.length > 0">
      <p class="category-title" (click)="item.open = !item.open">{{item.name}}<mat-icon>{{item.open ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon></p>

      <ng-container  *ngIf="item.open">
        <emodnet-menu-selector [categories]="item.categories" [parentCategory]="item" [map]="theMap" (filtersSelected)="selectFilters(item, true, parentCategory)">
        </emodnet-menu-selector>
      </ng-container >
    </div>

    <div class="filter-chip"  [style.background-color]="item.color ? item.color : ''" *ngIf="item.filters && item.filters.length > 0 && !item.selected && !item.menuExcluded" (click)="selectFilters(item, true, parentCategory)">
      <span class="filter-text">{{item.name}}</span>
    </div>
</ng-container >

