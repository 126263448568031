import { trigger, state, style, transition, animate } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Point } from 'leaflet';
import { FeatureInfo } from 'projects/leaflet/src/lib/interfaces/feature-info';
import { FeatureInfoPoint } from 'projects/leaflet/src/lib/interfaces/feature-info-point';
import { PlatformInfo } from 'projects/leaflet/src/lib/interfaces/platform-info';
import { PlatformsInfoPopupService } from './platforms-info-popup.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from '../../pipe/safe.pipe';

@Component({
  selector: 'emodnet-fe-platforms-info-popup',
  templateUrl: './platforms-info-popup.component.html',
  styleUrls: ['./platforms-info-popup.component.scss']
})
export class PlatformsInfoPopupComponent implements OnInit {
  public currentFeatureInfo?: FeatureInfo;
  public featureInfo: FeatureInfo[] = [];
  public lat: string = '';
  public lng: string = '';
  public currentIndex: number = 1;
  public parameters_group_longname_sanitized:string = '';
  public iframeArray: string[] = [];
  @Input() loading: boolean = true;
  @Output() closePressed = new EventEmitter<boolean>();
  constructor(private platformInfoService: PlatformsInfoPopupService, private sanitizer: DomSanitizer, public safe:SafePipe) { }

  ngOnInit(): void {
    this.platformInfoService.platformInfoChanged.subscribe((x: FeatureInfoPoint) => {
      this.iframeArray = [];
      const features = x.featureInfo
      this.featureInfo = features
      if(features.length > 0){
        this.currentIndex = 0;
        features.forEach((f)=>{

          if (f.properties.PLATFORMCODE && f.properties.integrator_id) {
            const iframeLink = `https://map.emodnet-physics.eu/platformpage/?platformcode=` + f.properties.PLATFORMCODE + '&showInIframe=true&integrator='+f.properties.integrator_id ;
            this.iframeArray.push(iframeLink);
          } else {
            const iframeLink = `https://map.emodnet-physics.eu/platformpage/?showInIframe=true&platformid=` + f.properties._id;
            this.iframeArray.push(iframeLink);
          }
        })
        this.lat = x.latLng.lat.toFixed(3);
        this.lng = x.latLng.lng.toFixed(3);
      }else{
        this.closePressed.emit(true);
      }
    });
  }

  navigate(isNext: boolean) {
    if (isNext) {
      if (this.currentIndex < this.iframeArray.length - 1)
        this.currentIndex++;
    } else {
      if (this.currentIndex > 0)
        this.currentIndex--;
    };
    // this.currentFeatureInfo = this.iframeArray[this.currentIndex];
    // this.parameters_name_sanitizer(this.currentFeatureInfo.properties?.parameters_group_longname)
  }
  close() {
    this.closePressed.emit(true);
    setTimeout(() => {
      const element = document.getElementById('content');
    const popup = document.getElementById('popup-info');
    if (element && popup) {
      element.style.width = '300px';
      element.style.height = '530px';
      popup.style.width = 'fit-content';
      popup.style.height = '82vh';
    }
    }, 1000);
  }
  parameters_name_sanitizer(name:string){
    if(name){
      let trimedLast = name.slice(0,-1)
      let trimed = trimedLast.slice(1)
      let splited = trimed.split(',')
      let sanitized = [...new Set(splited)]
      this.parameters_group_longname_sanitized = sanitized.toString()
    }
  }

  public isResizing: boolean = false;
  public initialWidth: number = 0;
  public initialHeight: number = 0;

  public resizeElement(): void {

    const resizer = document.getElementById('resizeIcon');
    const element = document.getElementById('content');
    const iframe = document.getElementById('iframe');
    const popup = document.getElementById('popup-info');

    if (!resizer || !element || !iframe || !popup) return;
    resizer.onmousedown = ((e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      this.isResizing = true;
      this.initialWidth = element.clientWidth;
      this.initialHeight = element.clientHeight;

      document.onmousemove = ((e: MouseEvent) => {
        if (!this.isResizing) return;
        if (e.clientX > window.innerWidth) return; // prevent from going out of the screen (width)
        if (e.clientY > window.innerHeight) return; // prevent from going out of the screen (height)

        const newWidth = this.initialWidth + e.movementX;
        const newHeight = this.initialHeight + e.movementY;
        if (newWidth <= 300) return;
        this.initialWidth = newWidth;
        this.initialHeight = newHeight;

        element.style.width = newWidth + 'px';
        element.style.height = newHeight + 'px';
        // iframe.style.width = newWidth + 'px';
        // iframe.style.height = newHeight + 'px';
        popup.style.width = newWidth + 'px';
        popup.style.height = newHeight + 'px';
      });
      document.onmouseup = (() => {
        // document.onmousemove = null;
        this.isResizing = false;
        document.onmouseup = null;
        document.onmousemove = null;

      });

    });

  }
}


