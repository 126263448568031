import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Map } from 'projects/leaflet/src/public-api';
import { IFilter } from 'projects/shared/src/core/filter.inerface';

@Component({
  selector: 'emodnet-fe-filters-dialog-expand',
  templateUrl: './filters-dialog-expand.component.html',
  styleUrls: ['./filters-dialog-expand.component.scss']
})
export class FiltersDialogExpandComponent implements OnInit, AfterViewInit {
  @ViewChild('componentPlaceholder', { read: ViewContainerRef }) public componentPlaceholder?: ViewContainerRef;
  @Output() filterReady = new EventEmitter<IFilter>();
  @Input() factory: ComponentFactory<IFilter> = {} as any;
  @Input() map?: Map;
  instance?: IFilter;
  constructor() { }
  ngAfterViewInit(): void {

    const ref = this.componentPlaceholder?.createComponent(this.factory);

    this.instance = ref?.instance as IFilter;

    if (this.instance && this.map)
      this.instance.map = this.map;
    this.instance.selected = true;
    this.filterReady.emit(this.instance);
  }

  ngOnInit(): void {


  }

}
