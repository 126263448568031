export enum LayerFilterCategoriesEnum {
  times = 'times',
  parameters = 'parameters',
  platform_type = 'platform_type',
  depth = 'depth',
  sea_basin = 'sea_basin',
  integrators = 'integrators',
  data_network = 'data_network',
  data_providers = 'data_providers',
  bbox = 'bbox',
  platform_name = 'platform_name'
}
