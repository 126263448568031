
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IFilterable,
  LayerFilterCategory,
} from 'projects/leaflet/src/public-api';
import { Map } from 'projects/leaflet/src/lib/map';
@Component({
  selector: 'emodnet-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, IFilterable {
  @Output() filtersChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() menuStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedInstitutions: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPolygon: EventEmitter<any> = new EventEmitter<any>();
  categories?: LayerFilterCategory[];
  isFilterOpen: boolean = true;
  menuInstitutionVisible = false;

  @Input() public set layerFilterCategories(
    v: LayerFilterCategory[] | undefined
  ) {

    this.categories = v;
    console.log('categories',v);

    this.prepareCategories(this.categories);
    console.log('categories 3 ',this.categories);

    this.filtersChanged.emit(true);
  }
  mapInstance?:Map;
  @Input() public set map(
    v: Map | undefined
  ) {
    this.mapInstance = v;
  }
  institutionslist:Array<any> = []
  @Input() public set institutions(
    v: any
  ) {

    if(!v) return;
    this.institutionslist = v;

  }

  constructor() {}

  ngOnInit(): void {
  }
  institutionsSelected(event: any) {
    this.selectedInstitutions.emit(event)
  }
  private prepareCategories(categories?: LayerFilterCategory[]) {
    categories?.forEach((f) => {
      if (f?.id == 'times')
        f.categories?.forEach((ff) =>
          ff.filters?.forEach((fi) => {
            if (ff.name == 'All' || ff.name == 'Custom') return;
            const filterTime = new Date();
            filterTime.setDate(filterTime.getDate() - +(fi.value ?? 0));
            filterTime.setUTCHours(0, 0, 0, 0);

            const today = new Date();
            today.setDate(today.getDate() + 1);
            today.setUTCHours(0, 0, 0, 0);

            if(filterTime && today){
              fi.filter = `${filterTime.toISOString()}/${today.toISOString()}`;
            }

          })
        );
      f.filters?.forEach((ff) => (ff.selected = f.selected ?? false));
      this.prepareCategories(f.categories);
    });
  }

  menuStateChange() {
    this.isFilterOpen = !this.isFilterOpen;
    if(this.isFilterOpen){
      document.documentElement.style.setProperty('--controlPosition', '320px');
    }else{
      document.documentElement.style.setProperty('--controlPosition', '10px');
    }
    this.menuStateChanged.emit(this.isFilterOpen);
  }

  outputIdsSelected(event:any){
    this.selectedPolygon.emit(event);
  }
}
