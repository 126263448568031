
<div class="logo">

    <div class="itioos-contianer" (click)="openInfoDialog()">
      <img src="assets/icons/infoDialog.svg" alt="" srcset=""> <span>IT-IOOS</span>
    </div>
    <a href="https://itineris.cnr.it/" target="_blank" rel="noopener noreferrer"><img src="assets/icons/Logo_ITINERIS.svg" alt="cnrismar-logo" /></a>

    <dialog class="dialog-style" id="dialog-itioos">
      <h2 class="title-style">IT-IOOS</h2>
      <p class="text-style">{{test}}</p>
      <button class="button-style" (click)="closeInfoDialog()">Chiudi</button>
    </dialog>
</div>
