import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayerFilterCategory } from 'projects/leaflet/src/public-api';

@Component({
  selector: 'emodnet-menu-selected',
  templateUrl: './menu-selected.component.html',
  styleUrls: ['../menu/menu.component.scss']
})
export class MenuSelectedComponent implements OnInit {
  @Output() filtersSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() categories?: LayerFilterCategory[];

  constructor() { }

  ngOnInit(): void {
  }

  selectFilters(category: LayerFilterCategory, selected: boolean) {
    category.selected = selected;
    category.filters?.forEach(f => f.selected = selected);
    this.filtersSelected.emit(true);
  }
}
