import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayerFilter, LayerFilterCategory } from 'projects/leaflet/src/public-api';
import { FilterDialogService } from 'projects/frontend/src/app/filters-dialog/filter-dialog.service';
import { Map } from 'projects/leaflet/src/lib/map';
const DEFAULT_FILTER_FUNCTION = (filters?: LayerFilter[]) => {
  const text = filters?.map(x => x.value)?.join(' OR ');
  return text ? `(${text})` : '';
}

@Component({
  selector: 'emodnet-menu-selector',
  templateUrl: './menu-selector.component.html',
  styleUrls: ['../menu/menu.component.scss']
})
export class MenuSelectorComponent implements OnInit {
  @Output() filtersSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() parentCategory?: LayerFilterCategory;
  @Input() categories?: any[];
  @Input() map?:Map;

  constructor(private FilterDialogService: FilterDialogService) { }
  search_data_network:string='';
  search_data_provider:string='';
  data_network_filtered:Array<any>=[]
  data_provider_filtered:Array<any>=[]
  theMap?:Map
  ngOnInit(): void {
    //this.parentCategory?.categories?.forEach(x => {});
    this.theMap = this.map
    console.log('categories',this.categories);

  }

  selectFilters(category: LayerFilterCategory, selected: boolean, parentCategory?: LayerFilterCategory) {
    if (parentCategory?.exclusive)
      parentCategory.categories?.forEach(x => {
        x.selected = !selected;
        x.filters?.forEach(f => f.selected = false);
      });

    category.selected = selected;
    category.filters?.forEach(f => f.selected = selected);

    if (parentCategory && parentCategory.id === 'times') {
      if(category.name === 'Custom'){
        // category.selected = false;
        this.FilterDialogService.openDialog(this.map, parentCategory)
      } else {
        category.filters?.forEach(f => {
          f.filter = f.value?.toString();
        });
      }
    } else if(parentCategory && parentCategory.id !== 'times'){
      category.filters?.forEach(f => {
        f.filter = f.value?.toString();
      });
    }
    this.filtersSelected.emit(selected);
  }

  performFilterSearch(event:any,item:any){
    if(item.id === 'data_network'){
      let list = item.categories[0].categories
      this.search_data_network = event
      this.data_network_filtered = list.filter((item:any) => item.name.toLowerCase().includes(event.toLowerCase()))
    }else if(item.id === 'data_providers'){
      let list = item.categories.filter((item:any) => item.name.toLowerCase().includes(event.toLowerCase()))
      this.search_data_provider = event
      this.data_provider_filtered = list.filter((item:any) => item.categories.filter((i:any)=>i.name.toLowerCase().includes(event.toLowerCase())))
    }
  }
}
