import '@angular/common/http';

declare module '@angular/common/http/http' {
  interface HttpParams {
    setNonNull(key: string, value: any): HttpParams;
  }
}
declare module '@angular/forms' {
  interface FormGroup {
    GetAllErrors(): string[];
  }
}

declare global {
  interface String {
    center(maxLength: number, fillString?: string): string;
    replaceAll(oldValue: string, newValue: string): string;
    toNumber(): number;
  }
/*   interface Object {
    toParams(): string;
  } */
}
