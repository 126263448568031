import { HttpParams } from '@angular/common/http';
import { FormGroup, ValidationErrors } from '@angular/forms';
import './extensions-declarations';

FormGroup.prototype.GetAllErrors = function (this: FormGroup) {
  return Object.keys(this.controls).map((key) => {
    const controlErrors: ValidationErrors | undefined | null = this.get(key)?.errors;
    if (controlErrors) {
      return Object.keys(controlErrors).map((keyError) => {
        return controlErrors[keyError];
      });
    } else {
      return [];
    }
  }).reduce((a, b) => a.concat(b));
};

HttpParams.prototype.setNonNull = function (
  this: HttpParams,
  key: string,
  value: any
) {
  let httpParams = this;
  if (value) {
    httpParams = this.set(key, value);
  }
  return httpParams;
};

String.prototype.center = function (
  maxLength: number,
  fillString?: string
): string {
  fillString = fillString || ' '; // If fillString is undefined, use space as default
  return this.length >= maxLength
    ? this.toString()
    : this.padStart((this.length + maxLength) / 2, fillString).padEnd(
        maxLength,
        fillString
      );
};
String.prototype.replaceAll = function (oldValue: string, newValue: string) {
  return this.replace(new RegExp(oldValue, 'g'), newValue);
};
String.prototype.toNumber = function () {
  const number = parseFloat(this.trim());
  if (!isNaN(number)) return number;
  else throw new Error(`${this} is not a number`);
};
/* Object.prototype['toParams'] = function (): string {
  let str = '';
  for (const key in this) {
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + (this as any)[key];
  }
  return str;
} */
