import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { Component, Input, OnInit } from '@angular/core';
import { PlatformInfo } from 'projects/leaflet/src/lib/interfaces/platform-info';

@Component({
  selector: 'emodnet-fe-platforms-menu',
  templateUrl: './platforms-menu.component.html',
  styleUrls: ['./platforms-menu.component.scss'],
})
export class PlatformsMenuComponent implements OnInit {
  _data?: PlatformInfo[];
  totalPlatformNumber?: number;
  @Input() loading: boolean = false;
  @Input() public set platformInfo(v: PlatformInfo[]) {
    this._data = v;
    this.totalPlatformNumber = this._data && this._data.length? 
      this._data.map(x => {
        if(x.platformNumber){
          return x.platformNumber
        }else{
          return 0
        }
      }).reduce((a, b) => +a + +b) : 0;
  }

  open: boolean = false;

  constructor() {}

  ngOnInit(): void {
    
  }
}
