<ng-container *ngFor="let item of institutionslist">
    <div class="category-container" *ngIf="institutionslist && institutionslist.length > 0" style="display: flex;">
        <!-- <input type="button" style="border-radius: 50%;border: none;width: 15px;height: 15px; justify-self: center;align-self: center;" [style.background]="item.color"/> -->
        <p class="category-title" style="width:70%;cursor: default;">{{item.name}}</p>
        <input checked type="checkbox" class="checkbox-round" [value]="item.filters" style="justify-self: self-end;align-self: center;" [id]="item.polygon"/>
    </div>

</ng-container >
<div class="buttons-container" >
    <input type="button" class="btn" value="OK" style="cursor: pointer; background-color: #8F4EC1;" (click)="submitInstitutions()"/>
</div>

