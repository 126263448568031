import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { MenuSelectorComponent } from './menu-selector/menu-selector.component';
import { MenuSelectedComponent } from './menu-selected/menu-selected.component';
import { MatIconModule } from'@angular/material/icon';
import {FormsModule} from '@angular/forms';
import { MenuInstitutionComponent } from './menu-institution/menu-institution.component'

@NgModule({
  declarations: [MenuComponent, MenuSelectorComponent, MenuSelectedComponent, MenuInstitutionComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule
  ],
  exports: [MenuComponent]
})
export class MenuModule { }
