import { HttpClient } from "@angular/common/http";

export class SharedData {
  private static instance: SharedData;
  private _httpClient?: HttpClient;
  private constructor() { }

  public static getInstance(): SharedData {
      if (!SharedData.instance) {
        SharedData.instance = new SharedData();
      }

      return SharedData.instance;
  }

  public set httpClient(v : HttpClient) {
    this._httpClient = v;
  }

  public get httpClient() : HttpClient {
    if (!this._httpClient) throw new Error('HttpClient is not defined');
    return this._httpClient;
  }

}
