import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { latLng, LatLng } from 'leaflet';

@Component({
  selector: 'emodnet-fe-click-bar',
  templateUrl: './click-bar.component.html',
  styleUrls: ['./click-bar.component.scss']
})
export class ClickBarComponent implements OnInit {
  @Input() loading: boolean = false;
  @Output() locationSearched: EventEmitter<LatLng> = new EventEmitter<LatLng>();
  @Output() menuStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() listToOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  isFilterOpen: boolean = true;
  isTextOpen: boolean = false;
  searchString: string = '';
  placeholderString: string = '41.3948976,2.0787283';
  isListOpen: boolean = false;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  openSearch(): void {
    if (!this.isTextOpen) {
      this.isTextOpen = !this.isTextOpen;
      return;
    }
    try {
      if (!this.searchString) throw new Error('Value is required');

      const latLng = this.searchString
        .trim()
        .split(',')
        .map((item: string) => item.toNumber());

      if (latLng.length < 2) throw new Error('Value is invalid');
      if (!this.isLatitude(latLng[0])) throw new Error('Latitude value is invalid');
      if (!this.isLongitude(latLng[1])) throw new Error('Longitude value is invalid');

      this.locationSearched.emit(new LatLng(latLng[0], latLng[1]));
      this.isTextOpen = !this.isTextOpen;
      this.searchString = '';
    } catch (error) {
      this.snackBar.open((error as Error).message, 'Close', { duration: 5000 });
    }
  }

  menuStateChange() {
    this.isFilterOpen = !this.isFilterOpen;
    this.menuStateChanged.emit(this.isFilterOpen);
  }

  isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;

  isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

  openList(){
    this.isListOpen = !this.isListOpen;
    this.listToOpen.emit(this.isListOpen);
  }
}
