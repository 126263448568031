import { CircleMarker as LeafletCircleMarker, CircleMarkerOptions, LatLngExpression } from "leaflet";

const MARKER_COLOR: string = '#F6B334';

const MARKER_OPTIONS: CircleMarkerOptions = {
  radius: 10,
  color: MARKER_COLOR,
  weight: 2,
  fillColor: MARKER_COLOR,
  fillOpacity: 0.7,
}

export class CircleMarker extends LeafletCircleMarker {
  extOptions: CircleMarkerOptions = MARKER_OPTIONS;
  constructor(latlng: LatLngExpression, extOptions?: CircleMarkerOptions) {
    super(latlng, {...MARKER_OPTIONS, ...extOptions});

    this.extOptions = {...MARKER_OPTIONS, ...extOptions};
  }
}
