<mat-icon class="arrow-button" (click)="menuStateChange()">{{
  isFilterOpen ? "navigate_next" : "navigate_before"
}}</mat-icon>
<div class="menu-container">
  <div class="buttons-container">
    <a target="_blank" class="btn">Login</a>
    <a target="_blank" class="btn">Store Layer</a>
  </div>
  <!-- *ngIf="!menuInstitutionVisible" -->

  <div class="selection-container" >

    <emodnet-menu-selected
    class="grid-auto-rows"
      [categories]="categories"
      (filtersSelected)="filtersChanged.emit(false)"
    ></emodnet-menu-selected>
  </div>
  <div class="filters-container">
    <div style="display: flex; justify-content: flex-start;padding:0px 20px;">
      <h2 style="font-weight: bold;padding-top: 15px;letter-spacing: 1px;" >Filters</h2>
      <!-- <img src="assets/icons/Paint-Brush-icon.png" width="20px" height="20px" alt="brush-icon" style="align-self: center;"/> -->
    </div>
    <emodnet-menu-selector
      [categories]="categories"
      [map]="mapInstance"
      (filtersSelected)="filtersChanged.emit(true)"
    ></emodnet-menu-selector>
    <!-- <emodnet-menu-institution
      [institutionslist]="institutionslist"
      [map]="mapInstance"
      (institutionsSelected)="institutionsSelected($event)"
      (polygonIdsSelected)="outputIdsSelected($event)"
    ></emodnet-menu-institution> -->
  </div>

</div>
