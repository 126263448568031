<div class="full-container">
  <div class="dialog-container">
    <button class="close_dialog_button" type="button" (click)="closePressed.emit()">
      <img src="./assets/icons/close.svg">
    </button>


    <div class="dialog-content" onclick="event.stopPropagation()" ondblclick="event.stopPropagation()">

      <div class="header-dialog">
        <h4 class="title" *ngIf="isFormVisible">
          Fill the form to contact EMODnet Physics HD
          <a href="https://www.emodnet-physics.eu/Map/includes/Documents/Emodnet_Physics_Portal_Short_Guide.pdf" target="_blank">
            <img src="./assets/icons/help.svg">
          </a>
        </h4>

        <form *ngIf="contactForm && isFormVisible" [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm)" class="form-contact">
          <div class="select-contact">
            <label class="label-form">
              Topic:
              <select formControlName="topic" placeholder="Select topic" [ngClass]="(contactForm?.get('topic')?.invalid && (contactForm?.get('topic')?.dirty || contactForm?.get('topic')?.touched))?'sel-err':'sel'">
                <option value="" disabled>* Select a topic</option>
                <option value='{"id":1, "string":"Web portal and related services"}'>Web portal and related services</option>
                <option value='{"id":2, "string":"Operational data"}'>Operational data</option>
                <option value='{"id":3, "string":"CDI and historical datasets"}'>CDI and historical datasets</option>
                <option value='{"id":4, "string":"Platforms coordination and management"}'>Platforms coordination and management</option>
                <option value='{"id":5, "string":"Any other"}'>Any other</option>
              </select>
            </label>
            <div class="text-error" *ngIf="contactForm?.get('topic')?.invalid && (contactForm?.get('topic')?.dirty || contactForm?.get('topic')?.touched)">
              Please provide a topic.
            </div>
          </div>
          <div class="input-contact">
            <label class="label-form">
              Name:
              <input formControlName="name" placeholder="Your name" [ngClass]="(contactForm?.get('name')?.invalid && (contactForm?.get('name')?.dirty || contactForm?.get('name')?.touched)) ?'inp-err':'inp'">
            </label>
            <div class="text-error" *ngIf="contactForm?.get('name')?.invalid && (contactForm?.get('name')?.dirty || contactForm?.get('name')?.touched)">
              Please provide a name.
            </div>
          </div>
          <div class="input-contact">
            <label class="label-form">
              Surname:
              <input formControlName="surname" placeholder="Your surname" [ngClass]="contactForm?.get('surname')?.invalid && (contactForm?.get('surname')?.dirty || contactForm?.get('surname')?.touched)?'inp-err':'inp'">
            </label>
            <div class="text-error" *ngIf="contactForm?.get('surname')?.invalid && (contactForm?.get('surname')?.dirty || contactForm?.get('surname')?.touched)">
              Please provide a surname.
            </div>
          </div>
          <div class="input-contact">
            <label class="label-form">
              Institute:
              <input formControlName="institute" placeholder="Your institute" [ngClass]="contactForm?.get('institute')?.invalid && (contactForm?.get('institute')?.dirty || contactForm?.get('institute')?.touched)?'inp-err':'inp'">
            </label>
            <div class="text-error" *ngIf="contactForm?.get('institute')?.invalid && (contactForm?.get('institute')?.dirty || contactForm?.get('institute')?.touched)">
              Please provide an institute.
            </div>
          </div>
          <div class="input-contact">
            <label class="label-form">
              Email:
              <input formControlName="email" placeholder="Your email" [ngClass]="contactForm?.get('email')?.invalid && (contactForm?.get('email')?.dirty || contactForm?.get('email')?.touched)?'inp-err':'inp'">
            </label>
            <div class="text-error" *ngIf="contactForm?.get('email')?.invalid && (contactForm?.get('email')?.dirty || contactForm?.get('email')?.touched)">
              Please provide an email.
            </div>
          </div>
          <div class="input-contact">
            <label class="label-form">
              Message:
              <textarea formControlName="message" placeholder="Your message" [ngClass]="contactForm?.get('message')?.invalid && (contactForm?.get('message')?.dirty || contactForm?.get('message')?.touched)?'det-err':'det'"></textarea>
            </label>
            <div class="text-error" *ngIf="contactForm?.get('message')?.invalid && (contactForm?.get('message')?.dirty || contactForm?.get('message')?.touched)">
              Please provide a message.
            </div>
          </div>
          <div class="send-div">
            <button type="submit" [disabled]="contactForm?.invalid" class="send">Send</button>
          </div>

        </form>

        <div *ngIf="isLoading" class="loading-panel">
          <span class="message">Loading data...</span>
          <div class="loader"></div>
        </div>

        <div *ngIf="!isFormVisible" class="no-form">
          <p class="message">{{message}}</p>
          <button class="send" (click)="closePressed.emit()">OK</button>
        </div>

      </div>

    </div>
  </div>
</div>
