<div class="container">
  <div class="content" *ngIf="!loading && open">
    <ng-container *ngFor="let item of _data">
      <img alt="" class="platform-icon" [src]="item.iconUrl" />
      <span class="platform-label">{{ item.name }}: </span>
      <span class="platform-number">{{ item.platformNumber }}</span>
    </ng-container>
  </div>
  <div class="content" *ngIf="loading && !open"></div>
  <div class="controls">
    <div class="info">
      <span>Total platforms: </span>
      <span class="number" *ngIf="!loading ">{{
        totalPlatformNumber ? totalPlatformNumber : "0"
      }}</span>
    </div>
    <div class="button loader" *ngIf="loading"></div>
    <mat-icon
      class="button"
      *ngIf="!loading && totalPlatformNumber && totalPlatformNumber > 0"
      (click)="open = !open"
      >{{ open ? "expand_more" : "expand_less" }}
    </mat-icon
    >
  </div>
</div>
