<h2 mat-dialog-title>Custom Date Filter</h2>
<mat-dialog-content>
  <div class="container">
<!--     <ng-container #componentPlaceholder> </ng-container> -->
    <!-- <div *ngFor="let item of test">{{item}}</div> -->
    <emodnet-fe-filters-dialog-expand
    *ngFor="let item of factories"
    (filterReady)="addFilter($event)"
    [map]="data.map"
    [factory]="item"></emodnet-fe-filters-dialog-expand>
  </div>
  <div *ngIf="errorText !== ''">
    <p style="color: red;"> {{errorText}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="actions">
    <button mat-flat-button (click)="close()">Close</button>
    <div></div>
    <button mat-flat-button (click)="setFilters()" color="primary">Set Filter</button>
  </div>
</mat-dialog-actions>
