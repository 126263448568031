<div class="container">
  <emodnet-fe-header></emodnet-fe-header>

  <div class="map-container">
    <div id="map" #leaflet class="map"></div>

    <emodnet-fe-platforms-info-popup
      id="popup-info"
      [@openCloseInfo]="featureInfoVisible ? 'open' : 'closed'"
      [loading]="featureInfoLoading"
      class="platform-info-popup"
      (closePressed)="featureInfoVisible = false">
    </emodnet-fe-platforms-info-popup>

    <emodnet-menu
      [@openCloseMenu]="menuVisible ? 'open' : 'closed'"
      class="menu"
      [map]="map"
      [institutions] = "institutions"
      [layerFilterCategories]="categories"
      (filtersChanged)="map?.updateOverlayLayers()"
      (menuStateChanged)="menuVisible = !menuVisible"
      (selectedInstitutions)="institutionsSelected($event)"
      (selectedPolygon)="institutionToAdd($event)">
    </emodnet-menu>

  </div>
</div>

