import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import 'projects/shared/src/public-api'
import { HttpClientModule } from '@angular/common/http';
import { MenuModule } from 'projects/menu/src/public-api';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickBarComponent } from './click-bar/click-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PlatformsMenuComponent } from './platforms-menu/platforms-menu.component';
import { PlatformsInfoPopupComponent } from './platforms-info-popup/platforms-info-popup.component';
import {MatButtonModule} from '@angular/material/button';
import { HeaderComponent } from './header/header.component';
import { HelpComponent } from './help/help.component';
import { FiltersDialogComponent } from './filters-dialog/filters-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FiltersDialogExpandComponent } from './filters-dialog/filters-dialog-expand/filters-dialog-expand.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { PlatformlistComponent } from './platformlist/platformlist.component';
import { SafePipe } from '../pipe/safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ClickBarComponent,
    PlatformsMenuComponent,
    PlatformsInfoPopupComponent,
    HeaderComponent,
    HelpComponent,
    FiltersDialogComponent,
    FiltersDialogExpandComponent,
    LoaderComponent,
    PlatformlistComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MenuModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressSpinnerModule,
  ],
  exports:[
    SafePipe
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    SafePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
