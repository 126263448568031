import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FeatureInfo } from 'projects/leaflet/src/lib/interfaces/feature-info';
import { PlatformInfo } from 'projects/leaflet/src/lib/interfaces/platform-info';
import { Map } from 'projects/leaflet/src/lib/map';
import { LayerFilterCategory } from 'projects/leaflet/src/public-api';
import { FilterDialogService } from './filters-dialog/filter-dialog.service';
import { LoaderService } from './loader/loader.service';
import { PlatformsInfoPopupService } from './platforms-info-popup/platforms-info-popup.service';
import { ConfigService } from './services/config.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [

    trigger('openCloseMenu', [
      state(
        'open',
        style({
          right: '0px',
        })
      ),
      state(
        'closed',
        style({
          right: '-305px',
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
    trigger('openCloseInfo', [
      state(
        'open',
        style({
          left: '10px',
        })
      ),
      state(
        'closed',
        style({
          left: '-300%',
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('1s')]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  @ViewChild('componentPlaceholder', { read: ViewContainerRef, static: true })
  public componentPlaceholder?: ViewContainerRef;
  @ViewChild('leaflet', { static: true })
  elementMap?: ElementRef<HTMLDivElement>;
  //selectedLayer:string=environment.defaultLayer?environment.defaultLayer:'EmodnetPlatformsMetadataV2024_internal'
  map?: Map;

  public menuVisible: boolean = true;
  public listVisible: boolean = false;
  public institutionVisible: boolean = true;
  public wmsLayerLoading: boolean = false;
  public platformInfoLoading: boolean = false;
  public featureInfoLoading: boolean = false;
  public featureInfoVisible: boolean = false;
  public categories?: LayerFilterCategory[];
  public normalizedCategories?: LayerFilterCategory[];
  public platformInfo: PlatformInfo[] = [];
  public featureInfo?: FeatureInfo[];
  public newCategoreis:Array<any> = []
  public categoryId: string = '';
  public layerObject: any;
  public bathymetricObject: any;
  public customFilters: Array<any> = [];
  public hitsoption: Array<any> = [];
  public institutions: Array<any> = [];
  public polygon: any;
  public cqlfilterInstitution: Subject<string> = new Subject<string>();
  public baselayer: any
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private platformInfoService: PlatformsInfoPopupService,
    public dialogService: FilterDialogService,
    private loaderService: LoaderService
  ) {}
  ngOnInit(): void {
    if (this.elementMap) {
      this.map = new Map(this.elementMap, this.http, {
        showZoom: true
      });
      this.configService.getConfigFiles().subscribe((x) => {
        this.layerObject = x[1];
        this.bathymetricObject = x[7];
        this.customFilters = x[4];
        this.hitsoption = x[3];
        this.polygon = x[5];
        this.institutions = x[6];

        this.categoriesSanitizer(x[2])
        this.newCategoreis.unshift(x[4][0])//add the time filter

        this.categories = this.newCategoreis;
        //let layers = [{...this.layerObject[0]}]
        let layers = this.layerObject[0]
        this.baselayer = layers;
        this.map?.addBaseLayers(x[0]);
        this.map?.addOverlayLayers(layers, this.newCategoreis, x[3]);
        this.map?.addBathymetricOverlay(x[7]);
        // this.map?.addInstitutionsLayer(x[5])

        this.map?.click.subscribe((c) => {
          this.featureInfoVisible = true;
        });
        this.map?.boundsChange.subscribe(b=>{
          this.featureInfoVisible = false;
        })

        this.cqlfilterInstitution.subscribe((cqi)=>(cqi));

          this.map?.addPolygonOverlay([],this.polygon);

        this.map?.overlayLayers.forEach((x) => {
          x.loading.subscribe((x) => {
            this.loaderService.show(x);
            this.wmsLayerLoading = x;
          });
          x.featureInfoLoading.subscribe((x) => (this.featureInfoLoading = x));
          x.platformsInfoLoading.subscribe(
            (x) => {
              this.platformInfoLoading = x;
            });

          x.platformsInfoChanged.subscribe((s) => (this.platformInfo = s));

          x.featureInfoChanged.subscribe((s) => {
            this.platformInfoService.SetPlatformInfo(s);
          });
        });
        /*
        this.map?.filterControlClicked.subscribe((x) =>
          this.dialogService.openDialog(this.map)
        );
        */
      });
    }
  }

  //update online json base on local json
  categoriesSanitizer(category:Array<any>){
    for (let item of category) {

      if(item.categories && item.categories.length > 0){
        if(item.id){//category id is needed for adding the code to platform type filter manually
          this.categoryId = item.id
        }
        this.categoriesSanitizer(item.categories);
      }
      if(item.filters){
        if(this.categoryId=='platform type'){//take the code part from filters
          //the code index is needed for platformtotalnumbers
          let text = item.filters;
          let first = text.indexOf("'");
          let second = text.indexOf("'",first+1);
          let diff = second - first;
          let code = text.substr(first+1, diff-1);
          item.filters = [{layerName:'platforms',value:item.filters,code:code}]
        }else{
          item.filters = [{layerName:'platforms',value:item.filters}]
        }
      }
    }
    this.newCategoreis = category
  }

  institutionsSelected(event: any) {
    this.map?.updateOverlayMapCqlFilter(event);
  }

  institutionToAdd(ids:any){
    if (ids.length > 0) {
      this.map?.addPolygonOverlay(ids,this.polygon);
      this.map?.addOverlayLayers(this.baselayer, this.newCategoreis, this.hitsoption);
    }

  }

}
