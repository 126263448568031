import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlatformHits } from 'projects/leaflet/src/lib/interfaces/platform-hits';
import { BaseLayer, OverlayLayer, LayerFilterCategory } from 'projects/leaflet/src/public-api';
import { forkJoin, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private subjectName = new Subject<any>();
  constructor(private http: HttpClient) { }

  public getConfigFiles() {
    return forkJoin([
      this.http.get<BaseLayer[]>('./assets/base-layers.json'),
      this.http.get<OverlayLayer[]>('./assets/overlay-layers.json'),
      this.http.get<LayerFilterCategory[]>('./assets/filterscopy.json'),
      this.http.get<PlatformHits[]>('./assets/platform-hits.json'),
      this.http.get<LayerFilterCategory[]>('./assets/filters.json'),
      this.http.get<LayerFilterCategory[]>('./assets/infrastructure.json'),
      this.http.get<any[]>('./assets/data_owner_italy.json'),
      this.http.get<any[]>('./assets/bathymetric-layer.json')
    ])
  }


  public getFilterJson(layer:string){
    return this.http.get<LayerFilterCategory[]>('https://data-erddap.emodnet-physics.eu/assets/'+ layer +'.json');
  }

}
