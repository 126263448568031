import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'emodnet-fe-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Output() public closePressed: EventEmitter<any> = new EventEmitter<any>();

  // formUrl = 'https://emodnet-physics.eu/Map/ARH/FeedSupportFeedback.aspx/GetSupportFeedback';
  formUrl = '/GenericProxyHttps/emodnet-physics.eu/Map/ARH/FeedSupportFeedback.aspx/GetSupportFeedback';
  message: string = '';
  isFormVisible: boolean = true;
  contactForm?: FormGroup;
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, private http: HttpClient) {
   }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      topic: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      institute: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });

  }

  onSubmit(form: FormGroup) {
    this.isLoading = true;
    this.isFormVisible = false;
    const topicObj = JSON.parse(form.value.topic);
    const topId = topicObj.id;
    const topicText = topicObj.string;

    const dataCompiled = {
      topicId: topId,
      topic: topicText,
      name: form.value.name,
      surname: form.value.surname,
      institute: form.value.institute,
      email: form.value.email,
      comments: form.value.message
    };

    this.http
    .post<any>(this.formUrl, dataCompiled).subscribe(data => {
      this.message = JSON.parse(data.d).message;
      this.isFormVisible = false;
      this.isLoading = false;
      this.contactForm?.reset();
    },
    err => {
      this.isFormVisible = false;
      this.isLoading = false;
      this.message = 'Error during send your feedback!';
    });
  }
}
