import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayerFilter, LayerFilterCategory } from 'projects/leaflet/src/public-api';
import { FilterDialogService } from 'projects/frontend/src/app/filters-dialog/filter-dialog.service';
import { Map } from 'projects/leaflet/src/lib/map';

@Component({
  selector: 'emodnet-menu-institution',
  templateUrl: './menu-institution.component.html',
  styleUrls: ['../menu/menu.component.scss']
})
export class MenuInstitutionComponent implements OnInit {
  @Output() institutionsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() polygonIdsSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() map?:Map;
  showSubmitBtn:boolean = false;
  anyInstitutionChecked:Array<any> = [];
  public institutionCqlFilter: string = '';
  public idsPolygonSelected: any;
  @Input() institutionslist?:Array<any>;
  constructor(private FilterDialogService: FilterDialogService) { }
  theMap?:Map
  ngOnInit(): void {
    this.theMap = this.map

  }
  selectInstitute(event:any){
    if(event.target.checked){
      this.anyInstitutionChecked.push(event.target.value)
    }else{
      var index = this.anyInstitutionChecked.indexOf(event.target.value)
      if(index !== -1){
        this.anyInstitutionChecked.splice(index,1)
      }
    }
    if(this.anyInstitutionChecked.length > 0){
      this.showSubmitBtn = true;
    }else{
      this.showSubmitBtn = false;
    }
  }
  submitInstitutions(){
    this.getAllSelectedInstitution();
    this.institutionsSelected.emit(this.institutionCqlFilter);
    this.polygonIdsSelected.emit(this.idsPolygonSelected);
  }

  public getAllSelectedInstitution(){
    const allCheckbox: HTMLCollection = document.getElementsByClassName('checkbox-round');
    let idsSelected: any = [];
    let cqlFilterStringForWMS = ''
   for (const key in allCheckbox) {
      const element: any = allCheckbox[key]
      if (element.checked) {
        cqlFilterStringForWMS === '' ? cqlFilterStringForWMS += element.value : cqlFilterStringForWMS += (' OR ' + element.value);
        element.id.split(' - ').forEach((el:any) => {
          if (!idsSelected.includes(el)) {
            idsSelected.push(el)
          }
      });
      }
   }
   this.idsPolygonSelected = idsSelected;
   this.institutionCqlFilter = cqlFilterStringForWMS;
  }

}

