import { EventEmitter, Injectable, Output } from '@angular/core';
import { FeatureInfo } from 'projects/leaflet/src/lib/interfaces/feature-info';
import { FeatureInfoPoint } from 'projects/leaflet/src/lib/interfaces/feature-info-point';
import { PlatformInfo } from 'projects/leaflet/src/lib/interfaces/platform-info';

@Injectable({
  providedIn: 'root'
})
export class PlatformsInfoPopupService {
  @Output() platformInfoChanged: EventEmitter<FeatureInfoPoint> = new EventEmitter<FeatureInfoPoint>();

  constructor() { }

  SetPlatformInfo(featureInfo: FeatureInfoPoint) {
    this.platformInfoChanged.emit(featureInfo);
  }
}
