<ng-container *ngFor="let item of categories">
  <div
    class="filter-chip filter-chip-selected"
    *ngIf="item.filters && item.filters.length > 0 && item.selected"
    (click)="selectFilters(item, false)"
  >
    <img
      class="filter-image"
      *ngIf="item.iconUrl"
      src="{{ item.iconUrl }}"
      alt=""
    />
    <span class="filter-text">{{ item.name }}</span>
  </div>
    <emodnet-menu-selected class="grid-auto-rows" [categories]="item.categories" (click)="selectFilters(item, false)"></emodnet-menu-selected>
</ng-container>
