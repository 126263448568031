import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emodnet-fe-platformlist',
  templateUrl: './platformlist.component.html',
  styleUrls: ['./platformlist.component.scss']
})
export class PlatformlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.addEventListener('filterUpdated', (e) => {  }, false);
  }

}
