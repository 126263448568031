import { HttpClient } from '@angular/common/http';
import { ElementRef, Input } from '@angular/core';
import {
  Control,
  control,
  CRS,
  Draw,
  FeatureGroup,
  LatLngBounds,
  LayerGroup,
  LeafletEvent,
  LeafletMouseEvent,
  Map as LeafletMap,
  TileLayer,
} from 'leaflet';
import * as L from 'leaflet';
import 'leaflet-draw';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CircleMarker } from './circle-marker';
import { SharedData } from './core/shared-data';
import { LayerFilterCategoriesEnum } from './enums/layer-filter-categories.enum';
import { FILTERCONTROL } from './filters-control';
import { BaseLayer } from './interfaces/base-layer';
import { FeatureInfoParamsSettings } from './interfaces/feature-info-params-settings';
import { LayerFilter } from './interfaces/layer-filter';
import { LayerFilterCategory } from './interfaces/layer-filter-category';
import { MapOptions } from './interfaces/map-options';
import { OverlayLayer } from './interfaces/overlay-layer';
import { PlatformHits } from './interfaces/platform-hits';
import { WMSTileLayer } from './wms-tile-layer';
import { environment } from '../../../frontend/src/environments/environment'
import { FilterService } from 'projects/frontend/src/app/services/filter.service';

const BASE_OPTIONS: MapOptions = {
  center: [42, 13],
  minZoom: 2,
  maxZoom: 11,
  zoom: 6,
  platformInfo: LayerFilterCategoriesEnum.platform_type,
  featureInfoParamsSettings: {
    wmsVersion: 1.1,
    feature_count: 10,
    request: 'GetFeatureInfo',
    INFO_FORMAT: 'application/json',
    service: 'WMS',
    version: '1.1.1',
    format: 'image/png',
    transparent: true,
    tiled: true,
    create_bbox: (wmsVersion: number, bounds: LatLngBounds, crs?: CRS) => {
      const nw = crs?.project(bounds.getNorthWest());
      const se = crs?.project(bounds.getSouthEast());
      if (wmsVersion >= 1.3 && crs === CRS.EPSG4326) {
        return [se?.y, nw?.x, nw?.y, se?.x].join(',');
      } else {
        return [nw?.x, se?.y, se?.x, nw?.y].join(',');
      }
    },
    create_projection_key: (wmsVersion: number) => {
      return wmsVersion >= 1.3 ? 'crs' : 'srs';
    },
  },
};

const BASE_LAYERS_PANE_NAME = 'baseLayersPane';
const OVERLAY_LAYERS_PANE_NAME = 'overlayLayersPane';
export class Map extends LeafletMap {
  selectedLayer: string = environment.defaultLayer ? environment.defaultLayer : 'EmodnetPlatformsMetadataV2024_internal'
  rectangleShowed: boolean = false

  wmsLayerTile?:WMSTileLayer;

  private baseLayersPaneName = BASE_LAYERS_PANE_NAME;
  private overlayLayersPaneName = OVERLAY_LAYERS_PANE_NAME;
  private overlayLayersGroup: LayerGroup = new LayerGroup([], {
    pane: OVERLAY_LAYERS_PANE_NAME,
  });
  private overlayLayersGroupBathimetry: LayerGroup = new LayerGroup([], {
    pane: OVERLAY_LAYERS_PANE_NAME,
  });
  private overlayLayersGroupPolygon: LayerGroup = new LayerGroup([], {
    pane: OVERLAY_LAYERS_PANE_NAME,
  });
  private marker?: CircleMarker;
  private drawnItems = new FeatureGroup();
  extOptions: MapOptions = BASE_OPTIONS;
  baseLayers: TileLayer[] = [];
  overlayLayers: WMSTileLayer[] = [];
  //private preparedLayerFilterCategories: LayerFilterCategory[] = [];
  layerFilterCategories: LayerFilterCategory[] = [];
  click: Observable<LeafletMouseEvent> = fromEvent<LeafletMouseEvent>(
    this,
    'click'
  );
  boundsChange: Observable<LatLngBounds> = fromEvent<LeafletEvent>(
    this,
    'moveend'
  ).pipe(map((ev) => this.getBounds()));
  bboxChanged: Subject<string> = new Subject<string>();
  filterControlClicked: Subject<boolean> = new Subject<boolean>();
  private overlaylayer: any
  private institutionslayer: any;
  public cqlIntstitution: any
  private platofrmHitsForCql: any[] = [];
  private overlayPlatformLayer: any;
  private currentFilter: any = {
    time:'',
    filter:''
  };

  constructor(
    element: ElementRef,
    httpClient: HttpClient,
    extOptions?: MapOptions,
  ) {
    super(element.nativeElement, BASE_OPTIONS);
    SharedData.getInstance().httpClient = httpClient;
    this.extOptions = { ...BASE_OPTIONS, ...extOptions };
    this.drawnItems.addTo(this);
    /*     this.click = fromEvent<LeafletMouseEvent>(this, 'click');
    this.boundsChange = fromEvent<LeafletEvent>(this, 'moveend').pipe(
      map((ev) => this.getBounds())
    ); */


    const baseLayersPane = this.createPane(this.baseLayersPaneName);
    baseLayersPane.style.zIndex = '' + 100;
    baseLayersPane.style.pointerEvents = 'none';

    const overlayLayersPane = this.createPane(this.overlayLayersPaneName);
    overlayLayersPane.style.zIndex = '' + 200;
    overlayLayersPane.style.pointerEvents = 'none';

    this.overlayLayersGroup.addTo(this);
    this.overlayLayersGroupBathimetry.addTo(this);
    this.overlayLayersGroupPolygon.addTo(this);
    if (this.extOptions?.showZoom) {
      this.zoomControl.setPosition('bottomright');
    }

    if (this.extOptions?.featureInfoParamsSettings) {
      let sub = this.clickSubcribe();

      //this.drawLocal.draw.toolbar.buttons.rectangle = 'Select Platforms';

      this.on(Draw.Event.CREATED, (event) => {
        const layer = event.layer;
        this.drawnItems.addLayer(layer);
        this.fitBounds(layer.getBounds());
        this.addFilter({
          name: 'bbox',
          id: 'bbox',
          selected: true,
          iconUrl: './crop.svg',
          menuExcluded: true,
          customFilter: true,
          filters: [
            {
              selected: true,
              layerName: 'platforms',
              filter: `BBOX(geometry,${(layer.getBounds() as LatLngBounds).toBBoxString()}, 'EPSG:4326')`,
            },
          ],
        });
        //this.setNewbbox(bbox)
        layer.bindPopup(`<a href="http://192.168.30.103:12400/mapviewer/" target="_block">Download Platforms</a>`, { closeOnClick: false }).openPopup();
        //the URL should be updated with the real one
      });

      this.on(Draw.Event.DRAWSTOP, (event) => {
        this.rectangleShowed = true;
        const timeout = setTimeout(() => {
          sub = this.clickSubcribe();

          clearTimeout(timeout);
        }, 500);
      });
      this.on(Draw.Event.DRAWSTART, (event) => {
        event.layer.featureInfoVisible = false;
        sub.unsubscribe();
      });
    }

    this.on(FILTERCONTROL, (x) => {
      this.filterControlClicked.next(true);
    });

  }

  addFilter(filterCategory: LayerFilterCategory) {
    const category = this.layerFilterCategories?.find(
      (x) => x.name == filterCategory.name
    );
    if (category) {
      if (category.customFilter) {
        category.selected = true;
        category.filters?.forEach((x) => {
          x.selected = true;
          filterCategory.filters?.length
            ? (x.filter = filterCategory.filters[0].filter)
            : null;
        });
      } else {
        if (category.exclusive) {
          category.categories?.forEach((x) => {
            x.selected = false;
            x.filters?.forEach((f) => (f.selected = false));
          });
        }

        if (filterCategory.filters) {
          category.filters?.push(...filterCategory.filters);
        }
        if (filterCategory.categories)
          category.categories?.push(...filterCategory.categories);
      }
    } else {
      this.layerFilterCategories?.push(filterCategory);
    }
    this.layerFilterCategories[0].categories = this.layerFilterCategories[0].categories?.slice(0,5);
    this.updateOverlayLayers();
  }

  private clickSubcribe(): Subscription {
    return this.click.subscribe((event) => {
      this.overlayLayers
        .filter((x) => x.wmsLayerOptions?.featureInfo)
        .forEach((x) => {
            x.setFeatureInfo(
              this,
              event,
              this.extOptions
                .featureInfoParamsSettings as FeatureInfoParamsSettings
            )
        }
        );

      if (this.marker) {
        this.removeLayer(this.marker);
        this.marker = new CircleMarker(event.latlng).addTo(this);
      } else {
        this.marker = new CircleMarker(event.latlng).addTo(this);
      }
    });
  }

  createOverlay(layer: OverlayLayer,
    layerFilterCategories: LayerFilterCategory[],
    platformHits: PlatformHits[]){
      this.overlayPlatformLayer = layer;
      this.platofrmHitsForCql = platformHits;
      this.layerFilterCategories = layerFilterCategories;

      const preparedLayerFilterCategories = this.prepareLayerFilterCategories(
        this.layerFilterCategories
      );
      this.overlayLayersGroup?.clearLayers();
      if (this.overlayLayersGroup) {
        const overlayLayer = new WMSTileLayer(layer, this);
        let platformHitsOptions = platformHits.find(
          (x) => x.layerName == layer.layerName
        );
        if (platformHitsOptions) {
          platformHitsOptions.layerFilterCategory = preparedLayerFilterCategories.find(
            (x) => x.id == platformHitsOptions?.layerFilterCategoryId
          );
          platformHitsOptions.layerTypeName = this.selectedLayer
        }
        overlayLayer.platformHitsOptions = platformHitsOptions;
        this.overlayLayers.push(overlayLayer);
        // this.layerFilterCategories.push(overlayLayer)
      }
  }

  addBaseLayers(layers: BaseLayer[]) {

    if (layers.length === 1) {
      const tileLayer = new TileLayer(layers[0].url, {
        attribution: layers[0].attribution,
        pane: this.baseLayersPaneName,
      });
      tileLayer.addTo(this);
      this.baseLayers.push(tileLayer);
    } else {
      const layersControl = control
        .layers()
        .setPosition('bottomleft')
      //.addTo(this);
      layers.forEach((layer, i) => {
        const tileLayer = new TileLayer(layer.url, {
          attribution: layer.attribution,
          pane: this.baseLayersPaneName,
        });
        if (i == 0) {
          tileLayer.addTo(this);
        }
        layersControl.addBaseLayer(tileLayer, layer.name);
        this.baseLayers.push(tileLayer);
      });
    }
  }

  addOverlayLayers(
    //layers: OverlayLayer[],
    layer: OverlayLayer,
    layerFilterCategories: LayerFilterCategory[],
    platformHits: PlatformHits[]
  ) {
    this.overlayPlatformLayer = layer;
    this.platofrmHitsForCql = platformHits;
    this.layerFilterCategories = layerFilterCategories;

        const preparedLayerFilterCategories = this.prepareLayerFilterCategories(
          this.layerFilterCategories
        );
        this.overlayLayersGroup?.clearLayers();
        if (this.overlayLayersGroup) {
          const overlayLayer = new WMSTileLayer(layer, this);

          let platformHitsOptions = platformHits.find(
            (x) => x.layerName == layer.layerName
          );
          if (platformHitsOptions) {
            platformHitsOptions.layerFilterCategory = preparedLayerFilterCategories.find(
              (x) => x.id == platformHitsOptions?.layerFilterCategoryId
            );
            platformHitsOptions.layerTypeName = this.selectedLayer
          }

          overlayLayer.platformHitsOptions = platformHitsOptions;
          this.overlayLayers.push(overlayLayer);
          overlayLayer.addTo(this.overlayLayersGroup);
          // this.layerFilterCategories.push(overlayLayer)
        }
        this.addDrawControl();
  }

  private prepareLayerFilterCategories(
    layerFilterCategories: LayerFilterCategory[]
  ): LayerFilterCategory[] {
    const preparedLayerFilterCategories: LayerFilterCategory[] = [];
    layerFilterCategories.forEach((category) => {
      if (!category) return;
      if (category.id === undefined) {
        let preparedCategory: LayerFilterCategory = {
          id: 'platform_name',
          filters: [],
        };
        category.filters?.forEach((x) => preparedCategory.filters?.push(x));
        // this.prepareFilterCategories(category, preparedCategory.filters ?? []);
        preparedLayerFilterCategories.push(preparedCategory);
      }

      let preparedCategory: LayerFilterCategory = {
        id: category?.id,
        filters: [],
      };
      category.filters?.forEach((x) => preparedCategory.filters?.push(x));
      this.prepareFilterCategories(category, preparedCategory.filters ?? []);

      preparedLayerFilterCategories.push(preparedCategory);
    });
    return preparedLayerFilterCategories;
  }

  private prepareFilterCategories(
    category: LayerFilterCategory,
    filters: LayerFilter[]
  ) {
    category.categories?.forEach((x) => {
      x.filters?.forEach((f) => {
        f.iconUrl = x.iconUrl ?? '';
        f.categoryName = x.name ?? '';
        filters.push(f);
      });
      this.prepareFilterCategories(x, filters);
    });
  }

  addDrawControl() {
  //   const ddrawOptions: Control.DrawConstructorOptions = {
  //     position: 'bottomright',
  //     draw: {
  //       rectangle: {
  //         shapeOptions: {
  //           fill: false,
  //         },
  //       },
  //       circle: false,
  //       polyline: false,
  //       polygon: false,
  //       marker: false,
  //       circlemarker: false,
  //     },

  //   };
  //   const drawControl = new Control.Draw(ddrawOptions);
  //   this.addControl(drawControl);
    /*
    const newControl = new FilterControl({ position: 'bottomleft' });
    this.addControl(newControl);
    */
  }

  updateOverlayLayers() {
    console.log('this.layerFilterCategories',this.layerFilterCategories);

    const categories = this.prepareLayerFilterCategories(
      this.layerFilterCategories
    ).filter((f) => f.filters?.some((s) => s.selected));

    if (!categories.find(s => s.id == 'bbox')?.filters?.some(s => s.selected)) {
      this.drawnItems.clearLayers();
    }
    if (this.rectangleShowed) {
      this.setView([42, 13], 6)
      this.rectangleShowed = false;
    }

    this.overlayLayers.forEach((f) => {
      if (f) {
        if (categories.length == 0 && f.onMap) {
          f.updateFilters('','');
          return;
        }

        f.onMap = false;
        f.remove();
      }
    });

    this.overlayLayers
      .filter((x) => {
        if (x) {
          return categories.find((s) =>
            s.filters?.some((ss) => ss.layerName == x.wmsLayerOptions?.layerName)
          )
        }
        return {}
      }
      )
      .forEach((f) => {
        if (f) {
          f.onMap = true;


          let filter = categories
            .filter((c) => c.id != 'times' && c.id)
            .map((cat) => {
              const text = cat.filters
                ?.filter(
                  (fil) =>
                    fil.selected && f.wmsLayerOptions?.layerName == fil.layerName
                )
                .map((x) => x.filter)
                ?.filter((fil) => fil)
                .join(' OR ');
              return text ? `(${text})` : '';
            })
            .filter((x) => x)
            .join(' AND ');

            const noDataTimeFilter = categories
            .find((c) => c.id == 'times')
            ?.filters?.find((ff) => ff.selected);
          if (noDataTimeFilter?.categoryName == 'Custom') {
            filter = filter ? filter + 'AND (' + noDataTimeFilter.value + ')' : filter + ' (' + noDataTimeFilter.value + ')'
          }
          const timeFilter: any = categories
            .find((c) => c.id == 'times')
            ?.filters?.find((ff) => ff.selected)?.filter;

          const event = new CustomEvent('filterUpdated', { detail: { filter, timeFilter } });
          document.dispatchEvent(event);

          if (timeFilter !== this.currentFilter.time) {
            this.currentFilter.time = timeFilter;
          }
          if (filter !== this.currentFilter.filter) {
            this.currentFilter.filter = filter
          }

          this.currentFilter.time = this.calculateTime(timeFilter);
          (f.wmsLayerOptions as any).time = this.currentFilter.time;
          f.updateFilters(this.currentFilter.filter, this.currentFilter.time);
          f.addTo(this.overlayLayersGroup);
        }
      });
  }

  // addInstitutionsLayer(layer: any) {
  //   this.institutionslayer = L.geoJSON(layer)
  //   this.overlayLayersGroup.addLayer(this.institutionslayer);

  // }
  // removeInstitutionsLayer() {
  //   this.overlayLayers.push(this.overlaylayer);//add layer with points
  //   this.updateOverlayLayers();
  //   this.overlayLayersGroup.removeLayer(this.institutionslayer);
  // }

  addBathymetricOverlay(layer:any){
    if (layer) {
      const overlayLayer = new WMSTileLayer(layer, this);
      overlayLayer.addTo(this.overlayLayersGroupBathimetry);
    }
  }

  updateOverlayMapCqlFilter(cqlFilter:any){
    const newCqlFilter = " (1=1) AND ("+ cqlFilter + ") OR (projects_code like 'EMSO')"
    this.overlayPlatformLayer['cql_filter'] = newCqlFilter;
    this.updateOverlayLayers();
  }

  calculateTime(time: string){
    let finalTimeFilter = time;
    if (time !== '' && !time.includes('Z/')) {
      const today = new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0);
      const firstTime = new Date(new Date().setTime(today - (this.currentFilter.time * 24 * 3600 * 1000) - (24 * 3600 * 1000)))
      finalTimeFilter = new Date(firstTime.getTime() - new Date().getTimezoneOffset()*60*1000).toISOString() + '/' + new Date(today - new Date().getTimezoneOffset()*60*1000).toISOString()
      this.currentFilter.time = finalTimeFilter;
    }
    return finalTimeFilter;
  }

  addPolygonOverlay(ids:any, polygon:any){
    if (ids.length > 0 && polygon) {
      this.overlayLayersGroupPolygon.clearLayers();
      for (const feature of polygon.features) {
        if(ids.includes(feature.properties.color)){
          this.institutionslayer = L.polygon(feature.geometry.coordinates, {color:feature.properties.color})
          this.institutionslayer.addTo(this.overlayLayersGroupPolygon);
        }
      }
    }else {
      for (const feature of polygon.features) {
        if(feature.properties.color){
          this.institutionslayer = L.polygon(feature.geometry.coordinates, {color:feature.properties.color})
          this.institutionslayer.addTo(this.overlayLayersGroupPolygon);
        }
      }
    }
  }

  removePolygonOverlay(){
    this.overlayLayersGroupPolygon.clearLayers();
  }
}
